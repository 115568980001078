import { useEffect, useState } from 'react';
import { Toaster } from 'sonner';
import { API_BASE_URL, food_types } from '../constants';
import React from 'react';
import { toast } from 'sonner';
import type { ScrappedDataType } from '../types';
import AddIcon from '@mui/icons-material/Add';

export default function Validator() {
    const [data, setData] = useState<ScrappedDataType[]>([]);

    const handleInputChange = (e, index: number, field) => {
        const newData = [...data];
        newData[index][field] = e.target.value;
        setData(newData);
    };

    const handleCheckboxChange = (e, index: number, field) => {
        const { checked } = e.target;
        const updatedData = [...data];
        updatedData[index][field] = checked;
        setData(updatedData);
    };

    const handleDuplicate = (index: number) => {
        const newRow = { ...data[index], created_at: new Date().toISOString() };
        const updatedData = [...data];
        updatedData.splice(index + 1, 0, newRow);
        setData(updatedData);
    };

    const handleAddRow = () => {
        const newRow = {
            vlog_date: '',
            vlog_link: '',
            vlog_title: '',
            influencer_name: '',
            name: '',
            full_caption: '',
            instagram_link: '',
            google_name: '',
            address: '',
            latitude: '',
            longitude: '',
            places_id: '',
            places_rating: '',
            places_user_ratings_total: '',
            validated_at: '',
            addVlogOnExistingPlace: false,
        };
        setData([...data, newRow]);
    };

    useEffect(() => {
        fetch(`${API_BASE_URL}/scrapedData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                const formattedData = result.data.map(
                    (item: ScrappedDataType) => ({
                        ...item,
                        addVlogOnExistingPlace: false,
                        vlog_date: formatDate(item.vlog_date), // Format vlog_date here
                    })
                );
                setData(formattedData);
            })
            .catch((error) => console.error('Error:', error));
    }, []);

    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const handleGoogleSearch = (index: number) => {
        const row = data[index];
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const postData = {
            name: row.name,
            food_type: 'Poisson',
            toBeAdded: false,
        };

        fetch(`${API_BASE_URL}/fetch_and_add_data_and_photos`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(postData),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.error) {
                    toast.error('An error occured', {
                        description: `Resto with name ${row.name} not found by Google Places API`,
                    });
                }
                if (result.success.includes('Resto already exist')) {
                    toast.warning('Resto already exist', {
                        description: `The record already exist in the database with name ${result.data.name} and influenceur ${result.data.restoAlreadyInDatabase}`,
                    });
                }
                const newData = [...data];
                newData[index] = {
                    ...newData[index],
                    google_name: result.data.name,
                    address: result.data.formatted_address,
                    latitude: result.data.latitude,
                    longitude: result.data.longitude,
                    places_id: result.data.place_id,
                    places_rating: result.data.rating,
                    places_user_ratings_total: result.data.user_ratings_total,
                    places_price_level: result.data.price_level,
                };
                setData(newData);
            })

            .catch((error) => console.error('Error:', error));
    };

    const handleValidation = (index: number) => {
        const row = data[index];
        const validationData = {
            id: row.id,
            name: row.name,
            food_type: row.food_type,
            vlog_date: row.vlog_date,
            vlog_title: row.vlog_title,
            vlog_link: row.vlog_link,
            instagram_link: row.instagram_link,
            influencer_name: row.influencer_name,
            toBeAdded: true,
            addVlogOnExistingPlace: row.addVlogOnExistingPlace,
        };
        toast.info('Ongoing', {
            description: `The record with name ${row.name} is being registered`,
        });

        fetch(`${API_BASE_URL}/fetch_and_add_data_and_photos`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(validationData),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.success?.includes('Resto already exist')) {
                    console.warn('Resto already exist:', result.data.name);
                    toast.warning('Resto already exist', {
                        description: `The record already exist in the database with name ${result.data.name} and influenceur ${result.data.restoAlreadyInDatabase}`,
                    });
                } else if (result.error?.includes('closed')) {
                    console.error('Resto is closed:', result.error);
                    toast.error('An error occured', {
                        description: `The restaurant with name ${row.name} is closed with status ${result.error}`,
                    });
                } else if (result.error) {
                    toast.error('An error occured', {
                        description: `${result.error}`,
                    });
                } else {
                    // Update the validated_at timestamp
                    const updatedData = data.map((item, i) =>
                        i === index
                            ? {
                                  ...item,
                                  validated_at: new Date().toISOString(),
                              }
                            : item
                    );
                    // Reorder data to place validated items at the bottom
                    setData(updatedData);
                    toast.success(
                        'The record has been sent to the database successfully.',
                        {
                            description: `The record with name ${row.name} has been registered`,
                        }
                    );
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                if (error.includes("SyntaxError: Unexpected token 'T',")) {
                    toast.success(
                        'The record has been sent to the database successfully.',
                        {
                            description: `The record with name ${row.name} has been registered`,
                        }
                    );
                }
                toast.warning(`An error occurred`, {
                    description: `The record could not be registered because of : ${error}`,
                });
            });
    };

    const handleValidationWithoutAdding = (index: number) => {
        const row = data[index];
        const validationData = {
            id: row.id,
        };
        toast.info('Ongoing', {
            description: `The record with name ${row.name} is being marked as validated without being registered`,
        });

        fetch(`${API_BASE_URL}/validate_scraped_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(validationData),
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.success) {
                    // Update the validated_at timestamp
                    const updatedData = data.map((item, i) =>
                        i === index
                            ? {
                                  ...item,
                                  validated_at: new Date().toISOString(),
                              }
                            : item
                    );
                    // Reorder data to place validated items at the bottom
                    setData(updatedData);
                    toast.success(
                        'The record has been validated successfully.',
                        {
                            description: `The record with name ${row.name} has been validated`,
                        }
                    );
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.warning(`An error occurred`, {
                    description: `The record could not be validated because of : ${error}`,
                });
            });
    };

    return (
        <>
            {/* <h1>Jaifaim Validator </h1> */}
            <Toaster position="top-right" richColors duration={2000} />
            <div className="max-w-8xl mx-auto p-2 rounded-lg">
                <h1 className="uppercase text-center text-xl font-medium py-2 ">
                    Jaifaim Validator{' '}
                </h1>
                <table className="min-w-full divide-y divide-gray-100 table-auto text-xs">
                    <thead className="bg-gray-100">
                        <tr>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '150px' }}
                            >
                                VLOG_DATE
                            </th>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '500px' }}
                            >
                                VLOG_LINK
                            </th>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '400px' }}
                            >
                                VLOG_TITLE
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                INFLUENCER_NAME
                            </th>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '400px' }}
                            >
                                FULL_CAPTION
                            </th>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '500px' }}
                            >
                                INSTAGRAM_LINK
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                SCRAPED_NAME
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                ACTIONS
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                DUPLICATE
                            </th>
                        </tr>
                        <tr>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                FOOD_TYPE
                            </th>

                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                LATITUDE
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                LONGITUDE
                            </th>

                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                PLACES_RATING
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                PLACES_RATINGS_TOTAL
                            </th>
                            <th
                                className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                style={{ width: '250px' }}
                            >
                                ADDRESS
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                GOOGLE_NAME
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                ADD VLOG ONLY
                            </th>
                            <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                VALIDATE
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white ">
                        {data.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.vlog_date}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'vlog_date'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.vlog_link}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'vlog_link'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.vlog_title}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'vlog_title'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.influencer_name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'influencer_name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>

                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '300px' }}
                                    >
                                        <textarea
                                            value={row.full_caption}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'full_caption'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md h-28"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.instagram_link}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'instagram_link'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                handleGoogleSearch(index)
                                            }
                                            className="px-3 py-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-600"
                                        >
                                            Search in Google
                                        </button>
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                handleDuplicate(index)
                                            }
                                            className="px-2 py-1 text-xs bg-orange-500 text-white rounded-md"
                                        >
                                            Duplicate
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <select
                                            value={row.food_type}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'food_type'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        >
                                            {food_types.map((food_type) => (
                                                <option
                                                    key={food_type}
                                                    value={food_type}
                                                >
                                                    {food_type}
                                                </option>
                                            ))}
                                        </select>
                                    </td>

                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.latitude}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'latitude'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.longitude}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'longitude'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>

                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.places_rating}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'places_rating'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={
                                                row.places_user_ratings_total
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'places_user_ratings_total'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td
                                        className="px-2 py-1 whitespace-nowrap"
                                        style={{ width: '250px' }}
                                    >
                                        <input
                                            type="text"
                                            value={row.address}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'address'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap">
                                        <input
                                            type="text"
                                            value={row.google_name}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    index,
                                                    'google_name'
                                                )
                                            }
                                            className="w-full p-1 text-xs border border-gray-300 rounded-md"
                                            disabled={!!row.validated_at}
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                handleValidationWithoutAdding(
                                                    index
                                                )
                                            }
                                            className={`px-2 py-1 text-xs rounded-md ${row.validated_at ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-red-500 text-white'}`}
                                            disabled={!!row.validated_at}
                                        >
                                            Don't save
                                        </button>
                                        <input
                                            type="checkbox"
                                            checked={row.addVlogOnExistingPlace}
                                            onChange={(e) =>
                                                handleCheckboxChange(
                                                    e,
                                                    index,
                                                    'addVlogOnExistingPlace'
                                                )
                                            }
                                            className="form-checkbox mx-4"
                                        />
                                    </td>
                                    <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() =>
                                                handleValidation(index)
                                            }
                                            className={`px-2 py-1 text-xs rounded-md ${row.validated_at ? 'bg-gray-400 text-gray-100 cursor-not-allowed' : 'bg-green-500 text-white'}`}
                                            disabled={!!row.validated_at}
                                        >
                                            Save
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="9">
                                        <div className="my-3">
                                            <hr className="border-t-4 border-gray-200" />
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleAddRow}
                        className="px-2 py-1 text-white bg-green-500 rounded-full"
                    >
                        <AddIcon />
                    </button>
                </div>
            </div>
        </>
    );
}
